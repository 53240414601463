import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import FeaturedContainer from "../components/featured-container"
import PostGeneralContainer from "../components/postGeneral-container"
import st from "./review.module.scss"

export default ({ data }) => (
  <Layout>
    <SEO
      title="Review"
      image={data.postsGeneral.nodes[0].thumbnail.fixed.src}
      imageWidth={data.postsGeneral.nodes[0].thumbnail.fixed.width}
      imageHeight={data.postsGeneral.nodes[0].thumbnail.fixed.height}
    />
    <header className={st.header}>
      <h1 className={st.sectionHeading}>Review</h1>
    </header>
    <PostGeneralContainer posts={data.postsGeneral.nodes} />
  </Layout>
)

export const query = graphql`
  {
    postsGeneral: allContentfulPostGeneral(
      filter: { category: { eq: "review" } }
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        id
        category
        title
        slug
        createdAt(formatString: "dddd MMM DD YYYY")
        featured
        thumbnail {
          title
          fluid(maxWidth: 600, maxHeight: 600, quality: 50) {
            ...GatsbyContentfulFluid
          }
          fixed(width: 500) {
            src
            width
            height
          }
        }
      }
    }
  }
`
